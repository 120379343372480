import React, { useEffect, useState } from "react";
import "./Menu.css";
import api from "./../services/api";

import CarregaMenu from "./../components/CarregaMenu";
import Loading from "../components/Loading";
export default function Menu() {
  const [categorias, setCategorias] = useState([]);
  const [cardapio, setCardapio] = useState([]);
  useEffect(() => {
    onLoad();

    async function onLoad() {
      const res = await api.get("/product/client/67726af733bbd2c1de6f9629");
      setCardapio(res.data);
      setCategorias([...new Set(res.data.map((item) => item.category))]);
    }
  }, []);

  return (
    <div className="menu my-2">
      <h1 className="cardapio">CARDÁPIO</h1>

      {categorias.length > 0 ? (
        <CarregaMenu cardapio={cardapio} categorias={categorias} />
      ) : (
        <Loading />
      )}
    </div>
  );
}
